<template>
    <div class="Activity">
        <Popup v-model="showActivity"  :close-on-popstate="true" :close-on-click-overlay="false">
            
            <a v-if="popdata.is_review" :href="'/Tasting/wine/Release?code='+this.popdata.code">
                <img v-if="popdata.img" width="100%"  :src="popdata.img" />
                <img v-else width="100%" src="@assets/images/404.png" />
            </a>
            <a v-else :href="'/Tasting/user/ActivityCode?code='+this.popdata.code">
                <img v-if="popdata.img" width="100%"  :src="popdata.img" />
                <img v-else width="100%" src="@assets/images/404.png" />
            </a>
            <div class="selected-close" @click.stop="popClose2"><i class="iconfont iconguanbi" style="font-size: 50px;"></i></div>
        </Popup>
    </div>
</template>
<script>
import { Popup } from 'vant';
export default {
    name:"Activity",
    components:{
        Popup,
    },
    data: function() {
            return {
                u:'',
            }
    },
    props: {
        showActivity: {
            type: Boolean,
            default: true
        },
        popdata:{
            type:Array,
            default:[]
        }
	},
    mounted: function() {
        //console.log(this.showActivity );
    },
    methods: {
        // goPages(){
        //     var urlcode="";
        //     var url ='/Tasting/wine/Release';
        //     var url2 = '/Tasting/user/ActivityCode';
        //     this.showActivity = false;
        //     //console.log(this.popdata.code);
        //     if(this.popdata){
        //         if(this.popdata.is_review){
        //             urlcode = url+'?code='+this.popdata.code
        //         }else{
        //             urlcode = url2+'?code='+this.popdata.code
        //         }
        //         return this.$router.push({ path: urlcode });
        //     }
        //     //window.location.href = url;
        //     this.$router.push({ path: url });
        // },
        popClose2:function(){
            this.$emit('setShowp'); // 传递关闭事件
        }
    }
}
</script>
<style lang="scss">
.Activity{
    .van-popup.van-popup--center{
        width: 300px;
        // height: 300px;
        background-color: rgba(255, 255, 255, 0);
    }
    .selected-close{
        position: relative;
        z-index: 100;
        color: #fff;
        border-radius: 0.1rem;
        font-size: 0.86rem;
        font-weight: 600;
        left: 150px;
        margin-left: -25px;
        margin-top: 10px;
    }
}
</style>