<template>
    <div v-if="show">
        
    <Popup v-model="show" round="true" 
        :close-on-click-overlay="false"
        :close-on-popstate="true"
    >
            <div class="popup-head">
                <div class="selected-close" @click.stop="popClose"><i class="iconfont iconguanbi"></i></div>
                <div class="text red">微醺人生 品味生活</div>
                <div class="text children">记录美好，向世界分享您的用酒</div>
            </div>

            <div class="popup-selected-box" @click.stop="goPages('/Tasting/wine/commentinfo?id='+popdata.judgeid,popdata.judgeid)">
                <div class="selected" v-if="popdata.selected">精选</div>
                <div v-if="popdata.images">
                    <Swipe 
                            :autoplay='3000'
                            :show-indicators='false'
                    >
                        <SwipeItem class="images-box" v-for="(item,index) in popdata.images" :key="index" style="text-align: center">
                            <img height="100%"  :src="item" style="overflow: hidden;"/>
                        </SwipeItem>
                        <!-- <SwipeItem class="images-box">
                            <img height="100%" width="100%" src="@assets/images/posters2.jpg"/>
                        </SwipeItem> -->
                        <!-- <img height="100%" src="@assets/images/posters2.jpg"/> -->
                    </Swipe>
                    <div class="content" style="width: 100%;">
                            <div class="Ellipses2">
                                {{popdata.content}}
                            </div>
                    </div>
                </div>
                <div class="fixd-box" >
                    <div class="avatar fixd">
                        <img :src="popdata.userinfo.avatar" />
                    </div>
                    <div class="userinfo fixd" >
                        <div class="user">
                            <span class="name">{{popdata.userinfo.nickname}}</span>
                            <span class="level">{{popdata.userinfo.rank_name}}</span>
                            <span class="exppr">酒量值：{{popdata.userinfo.exppr}}</span>
                        </div>
                        <div class="follow-box">
                            <span>关注：{{popdata.userinfo.follows_number}}</span>
                            <span>粉丝：{{popdata.userinfo.follow_number}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="fixd-box" v-else-if="popdata.category==1">
                    <div class="avatar fixd">
                        <img src="@assets/images/live-logo.png" />
                    </div>
                    <div class="userinfo fixd" >
                        <div class="user">
                            <span class="name">十二星</span>
                            <span class="level">官方</span>
                            <span class="exppr">无跳转页面</span>
                        </div>
                        <div class="follow-box">
                            <span>关注：0</span>
                            <span>粉丝：0</span>
                        </div>
                    </div>
                </div> -->
            </div>
        </Popup>
        </div>
</template>
<script>
import { Popup,Swipe,SwipeItem  } from 'vant';
export default {
    name:"Selectedpop",
    components:{
        Popup,Swipe,SwipeItem 
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        popdata:{
            type:Array,
            default:[]
        },
	},
    data:function(){
        return {
        }
    },
    mounted: function() {

    },
    methods: {
        goPages(url,id){
            if(id){
                this.$router.push({ path: url });
            }else{
                return false;
            }
            
        },
        popClose:function(){
            this.$emit('setShow'); // 传递关闭事件
        }
        
    }
}
</script>
<style lang="scss">
.van-popup.van-popup--round.van-popup--center{
    width: 80%;
    height: 510px;
    color: #000;
    //background-color: rgba(255, 255, 255, 0);
}
.van-popup__close-icon--top-right{
    
}
.popup-head{
    position: relative;
    width: 100%;
    .selected-close{
        position: absolute;
        z-index: 100;
        right: 8px;
        top: 5px;
        padding: 0.05rem 0.1rem;
        color:rgb(187, 116, 116);
        border-radius: 0.1rem;
        font-size: 0.3rem;
        font-weight: 600;
    }
    .text{
        text-align: center;
    }
    .red{
        padding-top: 0.2rem;
        color: #f00;
        font-size: 0.44rem;
    }
    .children{
        font-size:0.24rem;
        padding-bottom: 0.2rem;
    }
}
.popup-selected-box{
    //padding: 0.1rem 0.2rem;
    position:relative;
    .selected{
        position: absolute;
        z-index: 100;
        left: 8px;
        top: 5px;
        padding: 0.05rem 0.1rem;
        color:#fff;
        border-radius: 0.1rem;
        font-size: 0.2rem;
        font-weight: 600;
        
    }
    .content{
           position: absolute;
           font-size: 0.2rem;
           bottom: 0;
           padding: 0.1rem;
           background-color: rgba(0, 0, 0, 0.3);
           color: #fff;
           //border-top: 1px solid rgba(0, 0, 0, 0.4);
     }
    
    .fixd-box{
        display: flex;
        position: absolute;
        padding: 0.1rem 0.2rem;
        //bottom: 10px;
        //background-color: rgba(0, 0, 0, 0.767);
        border-radius: 0.2rem;
        .fixd{
            justify-content:flex-start;
            .user{
                //margin-top: 0.2rem;
                position: relative;
                span{
                    margin-left: 10px; 
                    font-size: 0.2rem;
                }
                .name{
                    font-size: 0.4rem;
                    font-weight: 600;
                    //padding-top: 0.1rem;
                    //color: #fff;
                    margin-top: 0.05rem;
                }
                .level{
                    padding: 0.04rem 0.1rem;
                    background-color: #f00;
                    color: #fff;
                    border-radius: 0.05rem;
                    position: relative;
                    top: -2px;
                }
                .exppr{
                    color: #f00;
                }
            }
            .follow-box{
                //margin-top: 0.1rem;
                span{
                    margin-left: 10px; 
                    font-size: 0.2rem;
                    color: rgb(170, 170, 170);
                    //padding: 0.02rem 0.1rem; 
                }
            }
        }
        .userinfo{
            position:relative;
            //top: 8px;
            //left:50px;
            //z-index: 3000;
        }
        .avatar{
            height: 50px;
            width: 50px;
            img{
                width: 100%;height: 100%;border-radius: 50px;
            }
        }
    }
    .images-box{
       position: relative;
       height: 380px;
       img{
           //border-radius: 5%;
       }
       
    }
    
}
</style>