<template>
    <div class="PackList" v-if="PackList.length > 0">
        <div class="tit" :v-if="title">
            <!-- <VanImage width="4rem" height="auto" lazy-load :src="require('@assets/images/goods/Packtit.png')" /> -->
            <h1>包装 & 礼盒
                <span>PACKING & OUTER BOX</span>
            </h1>
        </div>
        <div class="con">
            <div class="packplay" >
                <div class="box">
                    <div class="en_title">{{packListArray.en_title}}</div>
                    <div class="title">{{packListArray.title}}</div>
                    <div class="introduce">{{packListArray.introduce}}</div>
                    <div class="iamges" >
                        <VanImage  :src="packListArray.image" height="100%"  />
                    </div>
                </div>
            </div>
            <Tabs v-model="current" @change="onChange" :swipe-threshold="i" style="background-color: #dddddd;">
                <tab class="tab" :name="index" v-for="(item, index) in PackList" :key="index">
                    <template #title >
                        <div class="Thumb">
                            <div class="Thumbpic">
                                <VanImage  width="1.2rem"  lazy-load :src="item.image" />
                            </div>
                            <div class="Thumbname Ellipses1">
                                {{item.short_title}}
                            </div>
                        </div>
                    </template>
                </tab>
            </Tabs>
        </div>
    </div>
</template>
<script>
import { Tab, Tabs } from 'vant';
import { Image as VanImage } from 'vant';
export default {
    name: "PackList",
    props: {
        PackList: {
            type: Array,
            default: () => []
        },
        packListArray: {
            type: Array,
            default: () => []
        },
        title: {
            type: Boolean,
            value: true,
        },
        heights:{
            type: Number,
            value:4
        }
    },
    data: function() {
        return {
            current: 0,
            packshow: false,
            PackList: [],

            i:1
        };
    },
    components: {
        VanImage, Tab, Tabs
    },
    mounted: function() {
        this.seti();
    },
    methods: {
        seti:function(){
            this.i = 1;
        },
        onChange(index) {
            this.packshow=false;
            this.current=index;

            this.packListArray=this.PackList[index];
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .van-tab{background-color: #ddd;    padding: 0.1rem;}
    .van-tabs__nav{
        padding: 0;
    }
    .van-tabs__wrap {
        height: 2.5rem;margin:0.1rem 0;
        
    }
    .van-tab__text{
        .Thumb{
                background: #fff;
                text-align: center;
                border-radius: 0.1rem;
                padding: 0.1rem 0.2rem;
        }
    }
    .van-tabs__line{display:none;}
    .van-tab--active{
        .Thumb{border: 1px solid #333!important;}
    }
}
.PackList{
    background: #fff;
    .tit{
        color: #545454;
        font-size: 0.35rem;
        padding: 0.6rem 0.3rem;
        background: #e1e1e1;
        span{
            padding-left: 0.1rem;
        }
    }
    .packplay{
        text-align: center;
        .box{
            padding: 0.54rem 0 0rem 0;
            height: 8rem;
            .en_title{
                color: #464646;
                font-size: 0.55rem;
                line-height: 0.5rem;
                margin-bottom: 0.1rem;
            }
            .title{
                font-size: 0.3rem;
                //padding: 0rem 0rem 0.13rem 0rem;
                color: #464646;
                font-weight: 600;
                margin-bottom: 0.1rem;
            }
            .introduce{
                color: #ccc;
                font-weight: normal;
                padding-top: 0.03rem;
            }
            .iamges{
                padding: 0 1rem;
                // height: 4rem;
            }
        }
    }
    .Thumbpic{
        border-radius: 0.1rem;overflow: hidden;height: 1.2rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
    .Thumbname{text-align: center;font-size: 0.2rem;line-height: 0.5rem;width: 2rem;}
}
</style>