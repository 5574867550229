<template>
            <div v-if="!newslist.length" style="padding: 35px 20px;
                                                color: rgb(255, 0, 0);
                                                text-align: center;
                                                background-color: #fff;">
    <span >暂无动态</span></div>
            <div v-else class="newslist">
                <div class="item" v-for="(item, index) in newslist" :key="index" @click="goPages('/Tasting/news/info?id=' + item.id +'&store_id='+storeid)">
                    <div class="pic">
                        <VanImage
                                width="2rem"
                                height="2rem"
                                fit="cover" lazy-load 
                                :src="item.images"/>
                    </div>
                    <div class="newslistcon">
                        <div class="Ellipses1 tit">{{item.title}}</div>
                        <div class="Ellipses2 desc">{{item.content}}</div>
                        <div class="newsfooter">
                            <span>{{item.time}}</span>
                            <span class="Ellipses1 cityname">{{item.city}}</span>
                            <span class="Ellipses1 storename font-color">{{item.store_name}}</span>
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
import {  DynamicList } from "@api/tasting";
import { Image as VanImage } from 'vant';
    export default {
        name: "Newslist",
        components: {
            VanImage,
        },
        props:{
            storeid:{
                type:Number,
                value:0
            }
        },
        data: function() {
            return {
                newslist:[],
            };
        },
        
        mounted: function() {
            this.getDynamicList();
        },
        methods: {
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            //预览图片
            bindImagePreview: function(index, sonidnex) {
                ImagePreview({
                    images: this.list[index].images,
                    startPosition: sonidnex,
                });
            },
            getDynamicList: function() {
                console.log(this.storeid);
                DynamicList({
                    page: 1,
                    limit: 3,
                    store_id: this.$store.state.app.storeid||this.storeid
                }).then(res => {
                    this.newslist = res.data.data;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
        }
    };
</script>
<style lang="scss">
    .newslist{
            .item{
                background: #fff;padding: 0.2rem;overflow: hidden;
                .pic{
                    background: #f5f5f5;width: 2rem;height: 2rem;float: left;
                }
                .newslistcon{
                    padding-left: 2.2rem;height: 2rem;position: relative;
                    .tit{font-size: 0.28rem;font-weight: bold;line-height: 0.5rem;}
                    .desc{font-size: 0.2rem;text-align: justify;width: 60%;}
                    .newsfooter{
                        position: absolute;bottom: 0;right: 0;left: 2.2rem;font-size: 0.2rem;
                        span{float: left;display: inline-block;line-height: 0.3rem;}
                        .cityname{margin: 0 0.1rem;max-width: 1.4rem;}
                        .storename{float: right;max-width: 2rem;}
                    }
                }
            }
        }
</style>