<template>
    <div class="goods">
        <div class="goodsitem" v-for="(item, index) in goodsList" :key="index" @click.prevent="clicknavigateTo('/pages/goods_details/index?id=' + item.id,item.is_type)">
            <div class="pic">
                <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item.image" />
            </div>
            <div class="desc">
                <div>
                    <div class="name Ellipses1">{{item.en_name}}</div>
                    <div class="name Ellipses1">{{item.store_name}}</div>
                    <div class="price">￥{{item.sc_price}}</div>
                    <div class="buy" >
                        <div class="gobuy bg-color" v-if="item.is_type==0">商城直购价：￥{{item.price}} >></div>
                    </div>
                    <!-- <div v-if="getStauts(item.is_store_price,item.is_user_price,item.store_price,item.store)" class="store_price">招商价：￥{{item.store_price}}</div> -->
                    <!-- <div class="store_price">招商价：￥{{item.store_price}}</div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { Image as VanImage } from 'vant';
export default {
    name: "goodsList",
    props: {
        goodsList: {
        type: Array,
        default: () => []
        }
    },
    data: function() {
        return {
        value: true
        };
    },
    components: {
        VanImage
    },
    mounted: function() {
    },
    methods: {
        getStauts:function(is_store_price,is_user_price,store_price,store){
                if(store&&store_price!='0.00')return true;
                return is_store_price==0&&is_user_price&&store_price!='0.00';
        },

        clicknavigateTo: function(url,is_type) {
            if(is_type==1)return;
            wx.miniProgram.navigateTo({url: url})
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .van-image__error {
        background: #fff!important;
    }
}
.goods{
    margin-top: 0.2rem;margin-bottom: 0.2rem;white-space: nowrap;overflow: scroll;
    .goodsitem{
        background:#ffff;display:flex;border-top: 1px solid #e8e8e8;
        .pic{
            width: 2.6rem;background: #fff;position:relative;flex: none;
            .fen{position: absolute;top: 0.3rem;right: 0.1rem;width: 1.6rem;font-size: 0.24rem;}
            .fen .number{color: #999;position: relative;}
            .fen .number i{color: #ec652b;font-size: 0.3rem;font-weight: bold;margin-right: 0.05rem;}
            .fen .starsList{}
            .fen .starsList i{font-size: 0.3rem;color: #dadada;}
            .fen .starsList .font-color-cur{color: #ec652b;}
            .fen .descc{color: #999;font-size: 0.2rem;}
            .fen .descc .chateaujieshao0{border-top: 1px solid #999;margin: 0.1rem 0 0;padding-top: 0.1rem;}
        }
        .pic0{
            .van-image{width:1rem!important;height: 3.4rem !important;margin: 0.3rem 0 0 0.3rem;}
        }
        .desc{
            padding: 0.1rem 0.2rem 0.2rem;flex: 1;display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
            div{text-align: center;font-size: 0.2rem;color:#333;max-width: 4rem;}
            .name{text-align: center;font-size: 0.28rem;}
            .price{font-size: 0.6rem;padding: 0.1rem 0;}
            .store_price{font-size: 0.24rem;padding: 0.1rem 0;}
            .zhi{background: linear-gradient(to right,#e93323 0%,#fa6514 100%);border-radius: 0.06rem;color:#fff;font-size:0.16rem;display:inline-block;margin: 0 0.08rem;padding: 0.05rem 0.1rem;}
            .buy{
                .gobuy{margin: 0 auto;color:#fff;line-height:0.5rem;border-radius: 0.1rem;padding: 0 0.3rem;}
            }
        }
    }
    .goodsitem:first-child{border-top:none;}
}
</style>