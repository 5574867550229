<template>
    <div class="Store" v-if="storeinfo.id">
        <div class="hd">
            <div class="hdcon">
                <div class="Store_name">
                    <div class="name">{{storeinfo.name}}</div>
                    <div class="time">
                        <Icon name="clock-o">{{storeinfo.day_time}}</Icon>
                    </div>
                    <div class="Store_pic">
                        <VanImage width="2rem" height="1.4rem" fit="cover" lazy-load :src="storeinfo.image" />
                    </div>
                </div>
                <div class="iconlist">
                    <Icon name="location-o" />
                    <div class="iconlistp">{{storeinfo._detailed_address}}</div>
                </div>
                <div class="iconlist" v-if="storeinfo.is_phone">
                    <Icon name="phone" />
                    <div class="iconlistp">{{storeinfo.phone}}</div>
                </div>
            </div>
        </div>
        <div class="Swipestore">
            <Swipe @change="onChange" :initial-swipe="current" :duration="500" :loop="false" :show-indicators="false">
                <SwipeItem v-for="(item, index) in storeinfo.banner" :key="index">
                    <div class="Swipepic">
                        <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item" />
                    </div>
                </SwipeItem>
                <template #indicator>
                    <Tabs v-model="current" @change="onChange" :swipe-threshold="1">
                        <tab :name="item.id" v-for="(item, index) in storeinfo.banner" :key="index">
                            <template #title>
                                <div class="SwipeThumbpic">
                                    <VanImage width="auto" height="1.4rem" fit="cover" lazy-load :src="item" />
                                </div>
                            </template>
                        </tab>
                    </Tabs>
                </template>
            </Swipe>
        </div>
            <div class="newstit" style="margin:0 10px;padding:10px 0;
                                        border-top: 1px solid rgb(204, 204, 204);
                                        /* border-bottom: 1px solid #ccc; */
                                        font-weight: 600;
                                        color: #5f5f5f;" 
                                        >
                最新动态
            <router-link :to="{path:'/Tasting/news/list?store_id='+storeid}" style="float: right;">更多>></router-link>
            </div>
        <Newslist :storeid="storeid"></Newslist>
        <div class="gostore" v-if="storeinfo.is_type==0">
            <a style="    margin-right: 10px;"  href="javascript:void(0);" class="bg-color" @click.prevent="clickswitchTab('/pages/index/index?store_id=' + storeinfo.id)">进入本店商城</a>
                <a v-if="storeid!=562"  href="javascript:void(0);" class="bg-color" @click.stop="goPages('/Tasting/message/customer/'+storeinfo.uid)">给商家留言</a>

        </div>
        <div class="gostore" v-else>
                <a v-if="storeid!=562"  href="javascript:void(0);" class="bg-color" @click.stop="goPages('/Tasting/message/customer/'+storeinfo.uid)">给商家留言</a>
        </div>
    </div>
</template>
<script>
import wx from "weixin-js-sdk";
import Newslist from "../../components/Newslist";
import { Col, Row, Icon, Swipe, SwipeItem, Tab, Tabs } from 'vant';
import { Image as VanImage } from 'vant';
export default {
    name: "Store",
    components: {
             Newslist
        },
    props: {
        storeinfo: {
            type: Object,
            value: '',
        },
        goodsList: {
            type: Array,
            default: () => []
        },
        storeid: {
            type: Number,
            value: 0,
        }
    },
    data: function() {
        return {
            current: 0,
            StoreList: [
                'https://store.oupuhui.com/uploads/store/comment/20200924/f8c28e50b0f3a0eeb3fc888d20525c4c.jpg',
                'https://store.oupuhui.com/uploads/store/comment/20200924/f8c28e50b0f3a0eeb3fc888d20525c4c.jpg',
                'https://store.oupuhui.com/uploads/store/comment/20200924/f8c28e50b0f3a0eeb3fc888d20525c4c.jpg',
                'https://store.oupuhui.com/uploads/store/comment/20200924/f8c28e50b0f3a0eeb3fc888d20525c4c.jpg',
            ],
        };
    },
    components: {
        VanImage, Col, Row, Icon, Swipe, SwipeItem, Tab, Tabs , Newslist
    },
    mounted: function() {},
    methods: {
        onChange(index) {
            this.current = index;
        },
        clicknavigateTo: function(url) {
            wx.miniProgram.navigateTo({url: url})
        },
        clickswitchTab: function(url) {
            wx.miniProgram.switchTab({url: url})
        },
        goPages: function(url) {
                this.$router.push({ path: url });
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .van-swipe__track{transition-duration: 500ms!important;}
    .Swipestore{
        .van-tab{padding: 0 0.1rem;}
        .van-tabs__wrap {
            height: 1.6rem;margin:0.2rem 0;
        }
        .van-tabs__line{display:none;}
        .van-tab--active{
            .SwipeThumbpic{border: 1px solid #333;}
        }
    }
}
.Store{
    margin-top: 0.3rem;background:#fff;min-height:3.3rem;
    .hd{
        background: #000;height: 1.6rem;position: relative;margin-bottom: 1.8rem;
        .hdcon{
            background: #fff;position: absolute;border-radius: 0.2rem;padding: 0.2rem;left: 0.2rem;right:0.2rem;top: 1rem;
            .Store_name{
                position:relative;padding-right: 2.2rem;min-height:1rem;
                .name{font-size:0.36rem;color:#333;font-weight: bold;}
                .time{
                    line-height: 0.6rem;font-size: 0.2rem;
                    i::before{margin-right: 0.05rem;font-size:0.36rem;float:left;color:#e79b2d;}
                }
                .Store_pic{position:absolute;right: 0;top: -0.6rem;}
            }
            .iconlist{
                line-height: 0.4rem;
                i{float:left;}
                i::before{margin-right: 0.05rem;font-size:0.36rem;line-height: 0.4rem;}
                .iconlistp{padding-left: 0.4rem;line-height: 0.4rem;}
            }
        }
    }
    .Swipepic{
        overflow: hidden;height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .SwipeThumbpic{
        border: 1px solid #eee;border-radius: 0.1rem;overflow: hidden;height: 1.4rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
    .gostore{
        padding: 0.2rem;text-align: center;
        a{padding:0 0.3rem;line-height:0.6rem;display: inline-block;}
    }
}
</style>